<div class="image-cropper-cont">
  <image-cropper
    [imageBase64]="imageBase64"
    [maintainAspectRatio]="!!aspectRatio ? true : false"
    [aspectRatio]="aspectRatio"
    [resizeToWidth]="resizeToWidth"
    format="png"
    output="base64"
    (imageCropped)="onImageCropped($event)"
  ></image-cropper>
  <div class="actions-cont">
    <div class="text-link text-link-grey-darker" (click)="onCancel()">
      {{ "APP.CANCEL" | translate }}
    </div>
    <div class="text-link" (click)="cropImage()">
      {{ "APP.OK" | translate }}
    </div>
  </div>
</div>
