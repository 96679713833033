import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Asset, createDataUrl } from '@models/shared/asset.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Helpers } from '@utils/helpers';

@Component({
  selector: 'app-viewer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './viewer.dialog.html',
  styleUrl: './viewer.dialog.scss',
})
export class ViewerDialog {
  createDataUrl = createDataUrl;

  asset?: Asset;

  constructor(
    public dialogRef: MatDialogRef<ViewerDialog>,
    @Inject(MAT_DIALOG_DATA) public data: Asset,
  ) {
    this.asset = data;
  }

  close() {
    this.dialogRef.close();
  }

  get isVideo(): boolean {
    if (this.asset?.mimeType) {
      return Helpers.isVideoMimeType(this.asset.mimeType);
    }

    return false;
  }
}
