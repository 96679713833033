@if (asset) { @if (isVideo) {
<video
  class="viewer-video"
  controls="controls"
  [src]="asset.path || createDataUrl(asset)"
  type="video/mp4"
  [muted]="'muted'"
></video>
} @else {
<img class="viewer-image" [src]="asset.path || createDataUrl(asset)" />
} }

<div (click)="close()" class="close-button">
  <i class="fal fa-times"></i>
</div>
