import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageCroppedEvent, ImageCropperModule } from 'ngx-image-cropper';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-image-cropper',
  standalone: true,
  imports: [CommonModule, ImageCropperModule, TranslateModule],
  templateUrl: './image-cropper.component.html',
  styleUrl: './image-cropper.component.scss',
})
export class ImageCropperComponent {
  private croppedImageBase64: string = '';
  @Input() aspectRatio: number = 4 / 3;
  @Input() resizeToWidth: number = 0;
  @Input() imageBase64: string = '';

  @Output() imageCropped: EventEmitter<string> = new EventEmitter<string>();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

  onImageCropped(event: ImageCroppedEvent) {
    if (event.base64) {
      this.croppedImageBase64 = event.base64;
    }
  }

  cropImage() {
    this.imageCropped.emit(this.croppedImageBase64);
  }

  onCancel() {
    this.cancel.emit();
  }
}
